import React from 'react'
import logo from './logo.svg'
import AppProviders from './providers/AppProviders';
import AppRoutes from './routes';

function App() {
  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  )
}

export default App
