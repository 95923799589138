import React from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter, faSquareFacebook, faXing  } from '@fortawesome/free-brands-svg-icons';

interface Props {
    isBig: boolean,
    isOnDarkBg: boolean
}

const SocialLinks = (props: Props) => {
  const classBig = props.isBig ? 'is-big' : '';
  const classOnDarkBg = props.isOnDarkBg ? '' : 'is-dark';
  return (
      <SC.ul className={classBig + ' ' + classOnDarkBg}>
        <li>
          <a href="https://www.facebook.com/W4Marketing">
              {props.isBig}
            <FontAwesomeIcon icon={faSquareFacebook} />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/jewenzel">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/w4-marketing/">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </li>
        <li>
          <a href="https://www.xing.com/companies/w4">
            <FontAwesomeIcon icon={faXing} />
          </a>
        </li>
      </SC.ul>
  )
}
const SC: any = {}
SC.ul = styled.ul`
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0 0 30px 0;
    gap: 15px;
    flex-wrap: wrap;
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${props => props.theme.colors.secondary};
        width: 40px;
        height: 40px;
        border-radius: 100%;
        * {
            color: ${props => props.theme.colors.secondary};
            font-size: 16px;
        }
        &:hover *, &:hover {
            border-color: ${props => props.theme.colors.primaryAccent} !important;
            color: ${props => props.theme.colors.primaryAccent} !important;
        }
    }
    &.is-big {
        margin: 50px 0;
        gap: 30px;
        a {
            width: 70px;
            height: 70px;
            border-width: 3px;
            * {
                font-size: 30px;
            }
        }
    }
    &.is-dark {
        a {
            border-color: ${props => props.theme.colors.primary};
            * {
                color: ${props => props.theme.colors.primary}
            }
        }
    }
`
export default SocialLinks