import React from 'react'
import styled from 'styled-components';

const Spacer = () => {
  return <SC.hr />
}
const SC: any = {}
SC.hr = styled.hr`
    margin: 50px auto;
    width: 50px;
    border: 2px solid ${props => props.theme.colors.primaryAccent};
    border-radius: 5px;
`
export default Spacer