import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import { defaultTheme, GlobalStyles } from '../style/theme/default'
import { ThemeProvider } from 'styled-components'

type Props = {
  children: React.ReactNode
}

const AppProviders = (props: Props) => {
  const { children } = props
  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <GlobalStyles />
        {children}
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default AppProviders;