export const typography = {
  fontFamily: '\'Montserrat\', sans-serif',
  mainFontSizeS: '14px',
  mainFontSizeL: '16px',
  h1FontSizeS: '30px',
  h1FontSizeL: '35px',
  h2FontSizeS: '26px',
  h2FontSizeL: '30px',
  h3FontSizeS: '23px',
  h3FontSizeL: '25px',
  h4FontSizeS: '20px',
  h4FontSizeL: '22px',
  h5FontSizeS: '18px',
  h5FontSizeL: '20px',
  h6FontSizeS: '16px',
  h6FontSizeL: '18px',
}