import { createGlobalStyle } from 'styled-components'
import { device } from '../../breakpoints/device'
import { colors } from './colors'
import { typography } from "./typography";
import { layout } from "./layout";


export const defaultTheme = {
  colors: colors,
  typography: typography,
  layout: layout,
}

export const GlobalStyles = createGlobalStyle<{
  theme: {
    colors: {
      primary: string;
      secondary: string;
      tertiary: string;
      primaryAccent: string;
      secondaryAccent: string;
      tertiaryAccent: string;
    }
    typography: {
      fontFamily: string;
      mainFontSizeS: string;
      mainFontSizeL: string;
      h1FontSizeS: string;
      h1FontSizeL: string;
      h2FontSizeS: string;
      h2FontSizeL: string;
      h3FontSizeS: string;
      h3FontSizeL: string;
      h4FontSizeS: string;
      h4FontSizeL: string;
      h5FontSizeS: string;
      h5FontSizeL: string;
      h6FontSizeS: string;
      h6FontSizeL: string;
    }
    layout: {
      containerWidth: string;
      containerSideSpacing: string;
    }
  }
}>`
  body {
    &, * {
      font-family: ${props => props.theme.typography.fontFamily };
      color: ${props => props.theme.colors.primary};
      font-size: ${props => props.theme.typography.mainFontSizeS};
      font-weight: 400;
      @media ${device.laptop} {
        font-size: ${props => props.theme.typography.mainFontSizeL};
      }
    }
    h1, h2, h3, h4, h5, h6 {
      font-weight: 600;
      font-size: ${props => props.theme.typography.h1FontSizeS};
    }
    h1 {
      font-size: ${props => props.theme.typography.h1FontSizeS};
      @media ${device.laptop} {
        font-size: ${props => props.theme.typography.h1FontSizeL};
      }
    }
    h2 {
      font-size: ${props => props.theme.typography.h2FontSizeS};
      @media ${device.laptop} {
        font-size: ${props => props.theme.typography.h2FontSizeL};
      }
    }
    h3 {
      font-size: ${props => props.theme.typography.h3FontSizeS};
      @media ${device.laptop} {
        font-size: ${props => props.theme.typography.h3FontSizeL};
      }
    }
    h4 {
      font-size: ${props => props.theme.typography.h4FontSizeS};
      @media ${device.laptop} {
        font-size: ${props => props.theme.typography.h4FontSizeL};
      }
    }
    h5 {
      font-size: ${props => props.theme.typography.h5FontSizeS};
      @media ${device.laptop} {
        font-size: ${props => props.theme.typography.h5FontSizeL};
      }
    }
    h6 {
      font-size: ${props => props.theme.typography.h6FontSizeS};
      @media ${device.laptop} {
        font-size: ${props => props.theme.typography.h6FontSizeL};
      }
    }
    a {
      color: ${props => props.theme.colors.primaryAccent};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        color: ${props => props.theme.colors.primaryAccent};
      }
    }
    .ant-layout{
      background-color: ${props => props.theme.colors.tertiary};
    }
    .ant-layout-header {
      background-color: ${props => props.theme.colors.tertiary};
    }
  }
`