import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Spacer from "../../elements/Spacer";
import SocialLinks from "../../elements/SocialLinks";

const UnderConstruction = () => {
  const { t } = useTranslation()
  return (
    <MainLayout>
      <SC.Page>
        <h1>{t('app.underConstruction.title')}</h1>
        <p>{t('app.underConstruction.description')}</p>
        <Spacer />
        <h2>{t('app.underConstruction.followUs')}</h2>
        <SocialLinks isBig={true} isOnDarkBg={false} />
      </SC.Page>
    </MainLayout>
  )
}
const SC: any = {}
SC.Page = styled.div`
    text-align: center;
`
export default UnderConstruction