import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UnderConstruction from "../pages/UnderConstruction/page";


const AppRoutes = () => {
  return (
    <Routes>
      <Route path='*' element={<UnderConstruction />} />
    </Routes>
  )
}

export default AppRoutes