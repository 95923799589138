import React from 'react'
import { Layout } from 'antd';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logoW4 from '../assets/img/w4-logo.jpg';
import logoSpielo from '../assets/img/spielo-logo.jpg';
import SocialLinks from "../elements/SocialLinks";

const { Header, Content, Footer } = Layout;

type Props = {
  children: React.ReactNode
}


const MainLayout = (props: Props) => {
  const { children } = props
  const { t } = useTranslation()

  return (
    <SC.Layout>
      <SC.Header>
        <SC.Container>
            <Link to='/'>
                <SC.Logos>
                    <img alt="w4" src={logoW4} width="92px" height="80px"/>
                    <img alt="spielo" src={logoSpielo} width="92px" height="80px"/>
                </SC.Logos>
          </Link>
        </SC.Container>
      </SC.Header>
      <SC.Content className="site-layout">
        <SC.LayoutBG className="site-layout-background">
          <SC.Container className="container">
            {children}
          </SC.Container>
        </SC.LayoutBG>
      </SC.Content>
      <SC.Footer>
        <SC.Container>
          <SocialLinks isBig={false} isOnDarkBg={true} />
          <SC.Copyright>
            {t('app.footer.copyright')}{new Date().getUTCFullYear()}
          </SC.Copyright>
        </SC.Container>
      </SC.Footer>
    </SC.Layout>
  )
}

const SC: any = {}
SC.Layout = styled(Layout)`
  display: flex;
  min-height: 100vh;
`
SC.Header = styled(Header)`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: ${props => props.theme.layout.headerHeight};
  background-color: ${props => props.theme.colors.tertiary};
  display: flex;
  align-items: center;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
`
SC.Logos = styled.div`
  display: flex;
  gap: 30px;
`
SC.Content = styled(Content)`
  padding-top: ${props => props.theme.layout.headerHeight};
`
SC.LayoutBG = styled.div`
  background-color: ${props => props.theme.colors.tertiary};
  padding: 60px 0;
`
SC.Container = styled.div`
  padding: 0 ${props => props.theme.layout.containerSideSpacing};
  max-width: ${props => props.theme.layout.containerWidth};
  margin: 0 auto;
  width: 100%;
`
SC.Footer = styled(Footer)`
  text-align: center;
  background-color: ${props => props.theme.colors.primary};
  color:  ${props => props.theme.colors.tertiary};
  border-top: 50px solid ${props => props.theme.colors.primaryAccent};
  padding: 60px 0;
  justify-self: flex-end;
`
SC.Copyright = styled.small`
  color:  ${props => props.theme.colors.tertiary};
`
export default MainLayout;